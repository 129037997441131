import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Link } from 'gatsby'
import { mobile, rem } from '../../styles/functions'
import { colors, fontWeights } from '../../styles/variables'

export const ArticlesWrapper = styled.div`
  display: block;
  flex-grow: 1;
  flex-wrap: wrap;
  padding: 24px 0 40px 0;

  ${mobile(css`
    padding: 20px 0;
  `)}

  .articles-ad {
    display: block;
    height: 90px;
    background-color: ${colors.black};
    font-weight: ${fontWeights.medium};
    color: ${colors.contrast};
    text-align: left;
    box-sizing: border-box;
    font-size: ${rem(40)};
    padding: 20px 36px 0 36px;
    margin-bottom: 10px;
  }
`

export const ArticlesContainer = styled.div`
  display: block;
  margin: 0 -12px 16px -12px;
  overflow: auto;

  &:before,
  &:after {
    content: '';
    clear: both;
    display: table;
  }

  ${mobile(css`
    margin-bottom: 0;
  `)}
`

export const Wrapper = styled.div`
  display: inline-block;
  width: 33.3333%;
  padding: 0 12px;
  margin-bottom: 20px;
  box-sizing: border-box;
  float: left;

  a h2 {
    line-height: ${rem(24)};
    font-size: ${rem(20)};
    color: ${colors.text};
    margin: 0;
    padding-top: 4px;
    position: relative;

    &:before {
      content: '';
      display: inline-block;
      width: 30px;
      height: 2px;
      background-color: ${colors.primary};
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .article-image {
    border-radius: 6px 6px 0 0;

    a {
      height: 150px;
    }
  }

  .article-date {
    font-size: ${rem(11)};
    font-weight: ${fontWeights.medium};
    margin-bottom: 8px;
  }

  .article-content {
    box-sizing: border-box;
    background-color: ${colors.contrast};
    padding: 16px;
    border-radius: 0 0 6px 6px;
  }

  .article-excerpt {
    display: none;
  }

  &:first-of-type {
    width: 100%;
    position: relative;

    h2 {
      font-size: ${rem(20)};
      line-height: ${rem(24)};
    }

    .article-image {
      border-radius: 6px;

      a {
        height: 400px;
      }
    }

    .article-content {
      position: absolute;
      bottom: 20px;
      left: 28px;
      border-radius: 6px;
      width: 320px;
      max-width: 320px;
    }
  }

  ${mobile(css`
    display: block;
    width: 100%;
    padding: 0 10px;
    margin-bottom: 20px;
    float: none;
    flex-direction: column;

    .article-image {
      margin: 0;
    }

    &:first-of-type {
      .article-image {
        border-radius: 6px 6px 0 0;
      }

      .article-content {
        position: relative;
        bottom: auto;
        left: auto;
        border-radius: 0 0 6px 6px;
        width: 100%;
        max-width: 100%;
      }
    }
  `)}
`

export const Image = styled.div`
  display: block;
  overflow: hidden;
  margin: 0;
  width: 100%;
  max-width: 100%;
  background-color: ${colors.secondary};
  margin-bottom: 0;
  border-radius: 6px;
  box-sizing: border-box;

  a {
    display: block;
    height: 220px;
    position: relative;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  ${mobile(css`
    min-width: auto;
    width: 100%;
    margin: 0 0 20px 0;
    height: 200px;
  `)}
`

export const Content = styled.div`
  a {
    color: inherit;
    text-decoration: none;
  }

  h2 {
    font-size: ${rem(24)};
    font-weight: ${fontWeights.medium};
    letter-spacing: -2px;
    margin: -8px 0 0 0;
    color: ${colors.primary};
    line-height: ${rem(43)};
    margin-bottom: 14px;
  }
`

export const Meta = styled.div`
  margin-bottom: 24px;

  span {
    display: inline-block;
    border-right: 1px ${colors.grey} solid;
    padding-right: 10px;
    color: ${colors.grey};
    font-style: italic;
    font-weight: ${fontWeights.medium};
    font-size: ${rem(14)};
    letter-spacing: -1px;

    &:last-of-type {
      padding-right: 0;
      border-right: none;
    }
  }
`

export const Excerpt = styled.div`
  font-size: ${rem(16)};
  line-height: ${rem(30)};
  margin-bottom: 16px;
  display: none;

  ${mobile(css`
    line-height: ${rem(20)};
  `)}
`

export const ReadMore = styled(Link)`
  span {
    color: ${colors.secondary};
    font-weight: ${fontWeights.medium};
    font-size: ${rem(13)};
    text-transform: uppercase;
    text-decoration: underline;
  }
`

export const LoadMore = styled.div`
  display: block;
  text-align: center;
  width: 100%;
  margin: 24px 0 16px 0;

  button {
    background-color: ${colors.primary};
    border: none;
    border-radius: 2px;
    color: ${colors.contrast};
    outline: none;
    padding: 16px 36px;
    text-transform: uppercase;
    font-size: ${rem(12)};
    font-weight: ${fontWeights.medium};
    cursor: pointer;
  }
`

export const NoArticles = styled.div`
  font-size: 20px;
`

export const CategoriesContainer = styled.div`
  display: block;
  overflow: auto;
  padding-top: 40px;

  &:before,
  &:after {
    content: '';
    clear: both;
    display: table;
  }
`

export const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 40px 0 0 0;
  margin-bottom: 24px;
  box-sizing: border-box;
  position: relative;
  margin: 0 -10px 32px -10px;

  & > div,
  & > a {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: inherit;
    width: 33.3333%;
    padding: 0 10px;
  }

  .category-header {
    position: absolute;
    display: block;
    margin-bottom: 24px;
    top: 0;
    left: 0;

    span {
      font-size: ${rem(14)};
      font-weight: ${fontWeights.medium};
      text-transform: uppercase;
      color: ${colors.contrast};
      background-color: ${colors.primary};
      padding: 3px 10px;
      border-radius: 12px;
    }
  }

  .category-article-image {
    display: block;
    height: 200px;
    background-color: ${colors.secondary};
    margin: 0;
    border-radius: 6px 6px 0 0;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .category-article-title {
    font-size: ${rem(18)};
    font-weight: ${fontWeights.medium};
    padding: 12px 16px;
    background-color: ${colors.contrast};
    border-radius: 0 0 6px 6px;
  }

  ${mobile(css`
    flex-direction: column;
    margin: 0;
    box-sizing: border-box;

    & > div,
    & > a {
      box-sizing: border-box;
      display: block;
      text-decoration: none;
      color: inherit;
      width: auto;
      padding: 0;
      margin-bottom: 20px;
    }

    .category-article-image {
      display: block;
      height: 160px;
    }
  `)}
`
